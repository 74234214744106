import * as THREE from 'three/build/three.min.js';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import gsap from 'gsap';

const noise = `
  //  Classic Perlin 3D Noise
  //  by Stefan Gustavson
  //
  vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
  vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
  vec3 fade(vec3 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}

  float cnoise(vec3 P){
    vec3 Pi0 = floor(P); // Integer part for indexing
    vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
    Pi0 = mod(Pi0, 289.0);
    Pi1 = mod(Pi1, 289.0);
    vec3 Pf0 = fract(P); // Fractional part for interpolation
    vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
    vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
    vec4 iy = vec4(Pi0.yy, Pi1.yy);
    vec4 iz0 = Pi0.zzzz;
    vec4 iz1 = Pi1.zzzz;

    vec4 ixy = permute(permute(ix) + iy);
    vec4 ixy0 = permute(ixy + iz0);
    vec4 ixy1 = permute(ixy + iz1);

    vec4 gx0 = ixy0 / 7.0;
    vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
    gx0 = fract(gx0);
    vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
    vec4 sz0 = step(gz0, vec4(0.0));
    gx0 -= sz0 * (step(0.0, gx0) - 0.5);
    gy0 -= sz0 * (step(0.0, gy0) - 0.5);

    vec4 gx1 = ixy1 / 7.0;
    vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
    gx1 = fract(gx1);
    vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
    vec4 sz1 = step(gz1, vec4(0.0));
    gx1 -= sz1 * (step(0.0, gx1) - 0.5);
    gy1 -= sz1 * (step(0.0, gy1) - 0.5);

    vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
    vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
    vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
    vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
    vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
    vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
    vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
    vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

    vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
    g000 *= norm0.x;
    g010 *= norm0.y;
    g100 *= norm0.z;
    g110 *= norm0.w;
    vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
    g001 *= norm1.x;
    g011 *= norm1.y;
    g101 *= norm1.z;
    g111 *= norm1.w;

    float n000 = dot(g000, Pf0);
    float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
    float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
    float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
    float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
    float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
    float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
    float n111 = dot(g111, Pf1);

    vec3 fade_xyz = fade(Pf0);
    vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
    vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
    float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x);
    return 2.2 * n_xyz;
  }`;

const cubeVertex = `
  attribute vec3 aPosition;
  varying vec2 vUv;
  varying float vDist;
  uniform float uTime;
  uniform vec2 uMouse;
  uniform float uMouseSpeed;
  uniform float uMouseMovement;
  uniform vec2 uPointPositionOne;
  uniform vec2 uPointPositionTwo;
  uniform float uGlowValue;

  ${noise}

  void main() {
    vUv = uv;
    vec3 pos = position + aPosition;
    vec3 aPos = aPosition;

    float noise = abs(cnoise(vec3(aPos.x, aPos.y, uTime * 0.2)));

    vec2 firstPos = uPointPositionOne;
    firstPos -= uMouse * 0.2;
    float dist = length(aPos.xy - firstPos) - clamp(uMouseSpeed, 0.0, 0.1) * 1.75;
    dist -= uGlowValue;
    float distCoef = 0.0;
    if (dist < 0.55) {
      distCoef = 1.0 - dist / 0.55;
    }

    vec2 anotherPos = uPointPositionTwo;
    anotherPos += uMouse * 0.2;
    float dist2 = length(aPos.xy - anotherPos) - clamp(uMouseSpeed, 0.0, 0.1) * 1.75;
    dist2 -= uGlowValue;
    float distCoef2 = 0.0;
    if (dist2 < 0.55) {
      distCoef2 = 1.0 - dist2 / 0.55;
    }

    // pos.z += clamp(noise * uMouseSpeed, 0.0, 0.2);
    pos.z += noise * clamp(uMouseSpeed, 0.0, 0.04);

    vDist = distCoef + distCoef2;

    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
  }
`;

const cubeFragment = `
  varying vec2 vUv;
  varying float vDist;
  uniform float uTime;

  void main() {
    gl_FragColor = vec4(1.0, 1.0, 0.0, 0.4 * vDist);
  }
`;

const pointVertex = `
  varying vec2 vUv;
  varying float vDist;

  uniform float uPixelRatio;
  uniform float uTime;
  uniform vec2 uMouse;
  uniform float uMouseSpeed;
  uniform float uMouseMovement;
  uniform vec2 uPointPositionOne;
  uniform vec2 uPointPositionTwo;
  uniform float uGlowValue;

  ${noise}

  void main() {
    vUv = uv;
    vec3 p = position;
    float noise = abs(cnoise(vec3(p.x, p.y, uTime * 0.2)));
    float maxDist = 0.45;

    vec2 firstPos = uPointPositionOne;
    firstPos -= uMouse * 0.2;
    float dist = length(p.xy - firstPos) - uMouseSpeed * 1.25;
    dist -= uGlowValue;
    float distCoef = 0.0;
    if (dist < maxDist) {
      distCoef = 1.0 - dist / maxDist;
    }

    vec2 anotherPos = uPointPositionTwo;
    anotherPos += uMouse * 0.2;
    float dist2 = length(p.xy - anotherPos) - uMouseSpeed * 1.25;
    dist2 -= uGlowValue;
    float distCoef2 = 0.0;
    if (dist2 < maxDist) {
      distCoef2 = 1.0 - dist2 / maxDist;
    }

    vDist = distCoef + distCoef2;

    // p.z += clamp(noise * uMouseSpeed, 0.0, 0.2);
    p.z += noise * clamp(uMouseSpeed, 0.0, 0.001);

    vec4 mvPosition = modelViewMatrix * vec4(p, 1.0);
    gl_PointSize = uPixelRatio * 2.5 * (1.0 / -mvPosition.z);
    gl_Position = projectionMatrix * mvPosition;
  }
`;

const pointFragment = `
  varying vec2 vUv;
  varying float vDist;

  void main() {
    gl_FragColor = vec4(1.0, 1.0, 1.0, 0.75 * vDist);
  }
`;

export default class BackgroundGrid {
  constructor() {
    this.win = {
      canvas: document.querySelector('#c'),
      introSectionEl: document.querySelector('.intro'),
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
      aspectRatio: window.innerWidth / window.innerHeight,
      isMobile: window.innerWidth <= 767
    };

    this.mouse = {
      prev: new THREE.Vector2(0, 0),
      current: new THREE.Vector2(0, 0),
      speed: 0,
      movement: 0
    };

    this.onResize = this.onResize.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.animate = this.animate.bind(this);
  }

  init() {
    if (this.win.isMobile) return;

    this.initCamera();
    this.initScene();
    this.initRenderer();
    this.initRaycaster();
    // this.initControls();

    this.initPositions();
    this.initPlane();
    this.initCubes();
    this.initPoints();

    this.bindEvents();
    this.animate();

    // this.changePositions();
  }

  bindEvents() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('mousemove', this.onMouseMove);
  }

  initCamera() {
    // this.frustumSize = 2;
    // this.camera = new THREE.OrthographicCamera( this.frustumSize * this.win.aspectRatio / - 2, this.frustumSize * this.win.aspectRatio / 2, this.frustumSize / 2, this.frustumSize / - 2, 0.001, 100 );
    this.camera = new THREE.PerspectiveCamera(75, this.win.aspectRatio, 0.001, 100);
    this.camera.position.set(0, 0, 1.25);
  }

  initControls() {
    this.controls = new OrbitControls(this.camera, this.win.canvas);
  }

  initScene() {
    this.scene = new THREE.Scene();
  }

  initRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.win.canvas,
      antialias: true,
      alpha: true
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.win.winWidth, this.win.winHeight);
  }

  initRaycaster() {
    this.raycaster = new THREE.Raycaster();
    // this.mouse = new THREE.Vector2(-1000,-1000);
  }

  initPlane() {
    this.planeGeometry = new THREE.PlaneBufferGeometry(5, 5, 16, 16);
    this.planeMaterial =  new THREE.LineBasicMaterial({
      color: 0xffffff,
      transparent: true,
      opacity: 1,
      visible: false
    });
    this.plane = new THREE.Mesh(this.planeGeometry, this.planeMaterial);
    this.scene.add(this.plane);
  }

  initPositions() {
    this.positionList = [
      { one: new THREE.Vector2(1, -0.4), two: new THREE.Vector2(-1, 0.4) },
      { one: new THREE.Vector2(1, 0.4), two: new THREE.Vector2(-1, -0.4) },
      { one: new THREE.Vector2(0.5, -0.3), two: new THREE.Vector2(-0.5, 0.4) },
      { one: new THREE.Vector2(1.3, 0.4), two: new THREE.Vector2(-1.2, -0.4) },
      { one: new THREE.Vector2(0.5, 0.3), two: new THREE.Vector2(-0.5, -0.4) },

      { one: new THREE.Vector2(1.5, -0.4), two: new THREE.Vector2(-1.5, 0.4) },
    ];

    // this.positionIndex = Math.floor(Math.random() * this.positionList.length); // random position
    this.positionIndex = 5;

    this.pointPositionOne = {
      x: this.positionList[this.positionIndex].one.x,
      y: this.positionList[this.positionIndex].one.y
    };

    this.pointPositionTwo = {
      x: this.positionList[this.positionIndex].two.x,
      y: this.positionList[this.positionIndex].two.y
    };
  }

  changePositions() {
    let i = this.positionIndex;

    setInterval(() => {
      i = (i + 1) % this.positionList.length;

      const tl = gsap.timeline();

      tl.to([this.cubeMaterial.uniforms.uGlowValue, this.pointsMaterial.uniforms.uGlowValue], 2, { value: -1.2, ease: "power4.inOut" });
      tl.to(this.pointPositionOne, 1, { x: this.positionList[i].one.x, y: this.positionList[i].one.y }, '-=1.4');
      tl.to(this.pointPositionTwo, 1, { x: this.positionList[i].two.x, y: this.positionList[i].two.y }, '-=1.4');
      tl.to([this.cubeMaterial.uniforms.uGlowValue, this.pointsMaterial.uniforms.uGlowValue], 2, { value: 0 , ease: "power4.inOut"}, '-=1');

    }, 10000);
  }

  initCubes() {
    this.cubeNum = 40;
    const cubePos = new Float32Array(this.cubeNum * this.cubeNum * 3);
    let counter = 0;

    for (let x = -this.cubeNum / 2; x < this.cubeNum / 2; x++) {
      for (let y = -this.cubeNum / 2; y < this.cubeNum / 2; y++) {
        cubePos[3 * counter] = x / 6.25; // 1 / 0.16
        cubePos[3 * counter + 1] = y / 6.25;
        cubePos[3 * counter + 2] = 0;
        counter++;
      }
    }

    this.cubeGeometry = new THREE.PlaneBufferGeometry(0.16, 0.16, 1, 1);
    this.instancedCubeGeometry = new THREE.InstancedBufferGeometry().copy(this.cubeGeometry);
    this.instancedCubeGeometry.instanceCount = this.cubeNum * this.cubeNum;
    this.instancedCubeGeometry.setAttribute('aPosition', new THREE.InstancedBufferAttribute(cubePos, 3));
    this.cubeMaterial = new THREE.ShaderMaterial({
      transparent: true,
      side: THREE.DoubleSide,
      depthTest: false,
      vertexShader: cubeVertex,
      fragmentShader: cubeFragment,
      uniforms: {
        uTime: { type: 'f', value: 0 },
        uMouse: { type: 'v2', value: new THREE.Vector2(0, 0) },
        uMouseSpeed: { type: 'f', value: this.mouse.speed },
        uMouseMovement: { type: 'f', value: this.mouse.movement },
        uPointPositionOne: { type: 'v2', value: this.pointPositionOne },
        uPointPositionTwo: { type: 'v2', value: this.pointPositionTwo },
        uGlowValue: { type: 'f', value: 0 }
      }
    });
    this.cubes = new THREE.Mesh(this.instancedCubeGeometry, this.cubeMaterial);
    this.cubes.position.setZ(0.01);
    this.scene.add(this.cubes);
  }

  updateCubes() {
    if (this.cubeMaterial) {
      this.cubeMaterial.uniforms.uTime.value += 0.05;
      this.cubeMaterial.uniforms.uMouseSpeed.value = THREE.MathUtils.lerp(this.cubeMaterial.uniforms.uMouseSpeed.value, this.mouse.speed, 0.2);
      // this.cubeMaterial.uniforms.uPointPositionOne.value = this.pointPositionOne;
    }

    if (this.plane) {
      const intersects = this.raycaster.intersectObjects([this.plane]);

      if (intersects.length) {
        const point = intersects[0].point;
        this.cubeMaterial.uniforms.uMouse.value.x = point.x;
        this.cubeMaterial.uniforms.uMouse.value.y = point.y;
        this.pointsMaterial.uniforms.uMouse.value.x = point.x;
        this.pointsMaterial.uniforms.uMouse.value.y = point.y;
      }
    }
  }

  initPoints() {
    this.pointsGeometry = new THREE.BufferGeometry();
    const pointVertices = [];

    for (let x = -this.cubeNum / 2; x < this.cubeNum / 2; x++) {
      for (let y = -this.cubeNum / 2; y < this.cubeNum / 2; y++) {
        pointVertices.push(x / 6.25 + 0.08, y / 6.25 + 0.08, 0.01);
      }
    }

    this.pointsMaterial = new THREE.ShaderMaterial({
      transparent: true,
      depthTest: false,
      vertexShader: pointVertex,
      fragmentShader: pointFragment,
      uniforms: {
        uTime: { type: 'f', value: 0 },
        uMouse: { type: 'v2', value: new THREE.Vector2(0, 0) },
        uMouseSpeed: { type: 'f', value: this.mouse.speed },
        uMouseMovement: { type: 'f', value: this.mouse.movement },
        uPointPositionOne: { type: 'v2', value: this.pointPositionOne },
        uPointPositionTwo: { type: 'v2', value: this.pointPositionTwo },
        uPixelRatio: { type: 'f', value: window.devicePixelRatio },
        uGlowValue: { type: 'f', value: 0 }
      }
    });

    this.pointsGeometry.setAttribute('position', new THREE.Float32BufferAttribute(pointVertices, 3));
    this.points = new THREE.Points(this.pointsGeometry, this.pointsMaterial);
    this.scene.add(this.points);
  }

  updatePoints() {
    if (this.pointsMaterial) {
      this.pointsMaterial.uniforms.uTime.value += 0.05;
      this.pointsMaterial.uniforms.uMouseSpeed.value = THREE.MathUtils.lerp(this.pointsMaterial.uniforms.uMouseSpeed.value, this.mouse.speed, 0.2);
    }
  }

  render() {
    this.camera.lookAt(this.scene.position);

    this.updateMouse();

    this.raycaster.setFromCamera(this.mouse.current, this.camera);

    this.updateCubes();
    this.updatePoints();

    this.renderer.render(this.scene, this.camera);
  }

  animate() {
    this.render();
    requestAnimationFrame(this.animate);
  }

  onResize() {
    this.win.winWidth = window.innerWidth;
    this.win.winHeight = window.innerHeight;
    this.win.aspectRatio = window.innerWidth / window.innerHeight;
    this.camera.aspect = this.win.aspectRatio;

    // Orthographic camera
    // this.camera.left = - this.frustumSize * this.win.aspectRatio / 2;
    // this.camera.right = this.frustumSize * this.win.aspectRatio / 2;
    // this.camera.top = this.frustumSize / 2;
    // this.camera.bottom = - this.frustumSize / 2;

    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.win.winWidth, this.win.winHeight);
  }

  onMouseMove(e) {
    const x = ( e.clientX / window.innerWidth ) * 2 - 1;
    const y = - ( e.clientY / window.innerHeight ) * 2 + 1;

    this.mouse.current = { x: x, y: y };
  }

  updateMouse() {
    const mX = Math.abs(this.mouse.current.x - this.mouse.prev.x);
    const mY = Math.abs(this.mouse.current.y - this.mouse.prev.y);
    const mSpeed = Math.sqrt(mX * mX + mY * mY);

    this.mouse.speed = mSpeed;
    this.mouse.prev = this.mouse.current;
  }

  handleScroll() {
    if (!this.win.introSectionEl) return;
    const introRect = this.win.introSectionEl.getBoundingClientRect();
    this.win.canvas.classList.toggle('is-visible', introRect.top <= 0);
  }
}
