// import Chart from 'chart.js/auto';

import { Chart, ArcElement, DoughnutController } from 'chart.js';

Chart.register(ArcElement, DoughnutController);

export default class StatsChart {
  constructor() {
    this.DOM = {
      sectionEl: document.querySelector('[data-chart="block"]'),
      canvasEls: document.querySelectorAll('.stats__canvas')
    };

    this.numbersData = [
      [132060, 238680, 42190],
      [161140, 274310, 48630],
      [193010, 315270, 56050]
    ];
  }

  handleScroll() {
    if (!this.DOM.sectionEl) return;

    const rect = this.DOM.sectionEl.getBoundingClientRect();
    const isInView = rect.top <= window.innerHeight / 1.8;

    if (isInView) {
      this.DOM.canvasEls.forEach((el, i) => this.createChart(el, this.numbersData[i]));
    }
  }

  createChart(el, numData) {
    const isAnimated = el.getAttribute('data-chart-animated');

    if (isAnimated) return;

    el.setAttribute('data-chart-animated', 'true');

    const c = el.getContext('2d');
    var gradient = c.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, 'rgb(165,86,255)');
    gradient.addColorStop(1, 'rgb(2,238,255)');

    const data = {
      datasets: [{
          data: numData,
          backgroundColor: [ gradient, 'rgb(46,142,73)', 'rgb(0,121,223)' ],
          borderWidth: 0
      }]
    };

    const config = {
      type: 'doughnut',
      data: data,
      options: {
        responsive: true,
        animation: { duration: 1250 },
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false }
        }
      },
    };

    const chart = new Chart(el, config);

  }
}