import gsap from 'gsap';

export default class Header {
  constructor() {
    this.DOM = {
      el: document.querySelector('.global_header')
    };

    this.isLoaded = false;
    this.isHidden = false;

    this.idleCallback = null;
  }

  handleLoad() {
    this.isLoaded = true;
    this.idleCallback = setInterval(() => this.hide(), 4000);
  }

  handleScroll() {
    if (!this.isLoaded) return;
    this.show();
    clearInterval(this.idleCallback);
    this.idleCallback = setInterval(() => this.hide(), 4000);
  }

  show() {
    if (!this.isLoaded || !this.isHidden) return;
    this.isHidden = false;
    gsap.to(this.DOM.el, 1, { y: 0, ease: 'power4.out' });
  }

  hide() {
    if (!this.isLoaded || this.isHidden) return;
    this.isHidden = true;
    gsap.to(this.DOM.el, 1, { y: '-100%', ease: 'power4.out' });
  }
}