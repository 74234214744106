export default class Anchor {
  constructor(el) {
    this.DOM = { el : el };

    this.onClick = this.onClick.bind(this);
    this.init();
  }

  init() {
    if (!this.DOM.el) return;
    this.bindEvents();
  }

  bindEvents() {
    this.DOM.el.addEventListener('click', this.onClick);
  }

  onClick(e) {
    const href = this.DOM.el.getAttribute('href');
    this.DOM.target = document.querySelector(href);

    if (!this.DOM.target) return;

    e.preventDefault();
    setTimeout(() => {
      window.scrollTo({ top: this.DOM.target.offsetTop, behavior: 'smooth' });
    }, 10);
  }
}