import gsap from 'gsap';

export default class HeroVideo {
  constructor(isMobile, callback) {
    this.isMobile = isMobile;
    this.callback = callback;

    this.DOM = {
      el: document.querySelector('[data-video="el"]')
    };

    this.isLoaded = false;
  }

  init() {
    if (!this.DOM.el) return;
    this.getChildren();
    this.loadVideo();
    this.bindEvents();
  }

  getChildren() {
    this.DOM.videoWrapEl = this.DOM.el.querySelector('.hero__background-video');
    this.DOM.soundEl = document.querySelector('[data-video="sound"]');
  }

  bindEvents() {
    const video = this.DOM.videoWrapEl.querySelector('video');
    this.DOM.soundEl.addEventListener('click', e => {
      e.preventDefault();
      e.currentTarget.classList.toggle('is-active');
      video.muted = !video.muted;
    });
  }

  loadVideo() {
    this.DOM.videoEl = document.createElement('video');
    this.DOM.videoEl.type = "video/mp4";
    this.DOM.videoEl.muted = true;
    this.DOM.videoEl.playsinline = true;
    this.DOM.videoEl.autoplay = true;
    this.DOM.videoEl.loop = true;
    this.DOM.videoEl.controls = true;
    this.DOM.videoEl.src = this.isMobile ? '/informatics/assets/img/index/hero_video_sp.mp4': '/informatics/assets/img/index/hero_video_pc.mp4';
    this.DOM.videoEl.addEventListener('canplay', this.onVideoLoad.bind(this));
  }

  onVideoLoad() {
    if (this.isLoaded) return;

    this.isLoaded = true;

    this.DOM.videoEl.play();
    const video = this.DOM.videoWrapEl.querySelector('video');
    video.setAttribute('src', this.DOM.videoEl.src);

    const tl = gsap.timeline({ delay: 2.5 });
    tl.call(() => {
      const heroEl = document.querySelector('.hero');
      heroEl.style.opacity = 1;
      const linksEl = document.querySelector('.links');
      linksEl.style.opacity = 1;
      const overlayEl = document.querySelector('.overlay');
      overlayEl && overlayEl.classList.add('is-loaded');
      const canvasEl = document.querySelector('#c');
      canvasEl.style.opacity = 1;
    });
    tl.to(this.DOM.videoWrapEl, 1.5, { scale: 1, delay: 1 });
    tl.call(() => { window.onscroll = () => {}; });
    tl.to('.hero__grid', 1, { alpha: 0 }, '-=1');
    tl.to('.global_header', 1, { y: 0, ease: 'power4.out' });

    // tl.call(() => document.querySelector('.hero__grid').style.visibility = 'hidden' );

    if (this.callback) tl.call(this.callback);
  }
}
