import gsap from 'gsap';

export default class AnimController {
  constructor() {
    this.DOM = {
      animBlocks: Array.prototype.slice.call(document.querySelectorAll('[data-anim="block"]')),
      animEls: Array.prototype.slice.call(document.querySelectorAll('[data-anim-type]'))
    };

    this.config = {
      winHeight: window.innerHeight,
      threshold: 1.8 // 80%
    };

    this.onScroll = this.onScroll.bind(this);
  }

  init() {
    this.filterChildren();

    if (this.DOM.animBlocks.length || this.DOM.animEls.length) {
      this.onScroll();
      this.bindEvents();
    }
  }

  filterChildren() {
    this.DOM.animBlocks.forEach(block => {
      const animChildren = Array.prototype.slice.call(block.querySelectorAll('[data-anim-type]'));
      this.DOM.animEls = this.DOM.animEls.filter(el => !animChildren.includes(el));
    });
  }

  bindEvents() {
    window.addEventListener('scroll', this.onScroll, false);
  }

  onScroll() {
    this.DOM.animBlocks.forEach(block => this.animateBlock(block));
    this.DOM.animEls.forEach(el => this.animateEl(el));
  }

  animateEl(el) {
    const rect = el.getBoundingClientRect();
    const isInView = rect.top <= this.config.winHeight / this.config.threshold;

    if (isInView) {
      const delay = parseInt(el.getAttribute('data-anim-delay')) || 1;

      setTimeout(() => {
        el.classList.add('is-anim-over');
        this.DOM.animEls = this.DOM.animEls.filter(item => item !== el);
      }, delay);
    }
  }

  animateBlock(block) {
    const rect = block.getBoundingClientRect();
    const isInView = rect.top <= this.config.winHeight / this.config.threshold;

    if (isInView) {
      const animEls = Array.prototype.slice.call(block.querySelectorAll('[data-anim-type]'));

      animEls.forEach(el => {
        const delay = parseInt(el.getAttribute('data-anim-delay')) || 1;
        setTimeout(() => el.classList.add('is-anim-over'), delay);
      });

      this.DOM.animBlocks = this.DOM.animBlocks.filter(item => item !== block);
    }
  }
}