import '../scss/main.scss';

import HeroVideo from './modules/HeroVideo';
import AnimController from './modules/AnimController';
import BackgroundGrid from './modules/BackgroundGrid';
import FacilitiesOverlay from './modules/FacilitiesOverlay';
import Anchor from './modules/Anchor';
import Header from './modules/Header';
import StatsChart from './modules/StatsChart';

class App {
  constructor() {
    this.win = {
      width: window.innerWidth,
      height: window.innerHeight,
      aspectRatio: window.innerWidth / window.innerHeight,
      isMobile: window.innerWidth <= 767
    };
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    // window.onload = () => this.onWindowLoad();
    document.addEventListener("DOMContentLoaded", this.onDocReady.bind(this));
    window.onresize = () => this.onResize();
    window.addEventListener('scroll', this.onScroll.bind(this));
  }

  onDocReady() {
    window.scrollTo({ top: 0 });
    this.disableScroll();
    this.initHeader();
    this.initAnchors()
    this.initFacilitiesOverlay();
    this.initAnimation();

    this.onWindowLoad();
  }

  onWindowLoad() {
    this.initHeroVideo();
    this.initGrid();
    this.initCharts();
  }

  onResize() {
    this.win.width = window.innerWidth;
    this.win.height = window.innerHeight;
    this.win.aspectRatio = window.innerWidth / window.innerHeight;
    this.win.isMobile = window.innerWidth <= 767;
  }

  onScroll() {
    if (this.header) {
      this.header.handleScroll();
    }

    if (this.grid) {
      this.grid.handleScroll();
    }

    if (this.statsCharts) {
      this.statsCharts.handleScroll();
    }
  }

  disableScroll() {
    window.onscroll = () => window.scrollTo(0, 0);
  }

  enableScroll() {
    window.onscroll = () => {};
  }

  initAnimation() {
    this.animController = new AnimController();
    this.animController.init();
  }

  initHeroVideo() {
    this.heroVideo = new HeroVideo(this.win.isMobile, () => {
      this.header.handleLoad();
      document.body.classList.add('is-video-loaded');
    });
    this.heroVideo.init();
  }

  initGrid() {
    this.grid = new BackgroundGrid();
    this.grid.init();
  }

  initFacilitiesOverlay() {
    this.facilitiesOverlay = new FacilitiesOverlay();
    this.facilitiesOverlay.init();
  }

  initAnchors() {
    this.anchorEls = document.querySelectorAll('[data-anchor="el"]');
    this.anchorEls.forEach(el => new Anchor(el));
  }

  initHeader() {
    this.header = new Header();
  }

  initCharts() {
    this.statsCharts = new StatsChart();
  }
}

const app = new App();
app.init();
