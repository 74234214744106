import Swiper from 'swiper/bundle';

export default class FacilitiesOverlay {
  constructor() {
    this.DOM = {
      el: document.querySelector('[data-facilities="overlay"]')
    };
  }

  init() {
    if (!this.DOM.el) return;
    this.getChildren();
    this.bindEvents();
    this.initSlider();
  }

  getChildren() {
    this.DOM.openEl = document.querySelector('[data-facilities="open"]');
    this.DOM.closeEl = document.querySelector('[data-facilities="close"]');
    this.DOM.sliderEl = this.DOM.el.querySelector('[data-facilities="slider"]');
  }

  bindEvents() {
    this.DOM.openEl.addEventListener('click', this.onOpenClick.bind(this));
    this.DOM.closeEl.addEventListener('click', this.onCloseClick.bind(this));
  }

  onOpenClick(e) {
    e.preventDefault();
    this.DOM.el.classList.add('is-open');
    this.slider.slideTo(1, 0);
  }

  onCloseClick(e) {
    e.preventDefault();
    this.DOM.el.classList.remove('is-open');
  }

  initSlider() {
    this.slider = new Swiper(this.DOM.sliderEl, {
      speed: 750,
      loop: true,
      slidesPerView: 1,
      navigation: {
        nextEl: '.facilities__nav-item--next',
        prevEl: '.facilities__nav-item--prev'
      },
      pagination: {
        el: '.facilities__pagination',
        type: 'bullets',
        clickable: true
      }
    });
  }
}